<template>
    <div>
        <!-- :today="today" 
        :prev="prev" 
        :next="next" 
        :today="today" 
        :related_object="related_object"
        :relatedInfo="relatedInfo"
        :addCalendar="addCalendar"
        :todayCheck="todayCheck"
        :addEventCheck="addEventCheck" -->
        <Header
            :prev="prev"
            :next="next"
            :today="today" 
            :activeType="activeType"
            :startDay="startDay"
            :downloadFile="downloadPDF"
            :shareFile="shareFile"
            
            :handleChangeType="handleChangeType"/>

        <div class="calendar">
            <a-skeleton :loading="loading">
                <table>
                    <thead>
                        <tr>
                            <th>Сотрудники</th>
                            <th v-for="(weekDay, index) in weekDaysWithDates" :key="index">
                                <span class="uppercase"> {{ weekDay.name }}, </span>
                                {{ weekDay.date }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in users.results" :key="user.id">
                            <td>
                                <div class="employee-inofo">
                                    <Profiler
                                        :avatarSize="22"
                                        nameClass="text-sm"
                                        :user="user"
                                        showUserName/>
                                </div>
                            </td>

                            <td
                                class="day-column"
                                v-for="(weekDay, index) in user.week"
                                :key="user.id + index">
                                <EventsPopover
                                    :user="user"
                                    :weekDay="weekDay" />
                               
                            </td>
                        </tr>
                    </tbody>
                </table>
            </a-skeleton>
        </div>
    </div>
</template>

<script>
import EventsPopover from "./EventsPopover.vue";
import Header from "./Header";
import Vue from "vue";
export default {
    components: {
        Header,
        EventsPopover
    },
    props: {
        organization: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            users: {},
            activeType: "day",
            loading: false,
            startDay: this.$moment(),
        };
    },
    computed: {
        weekDaysWithDates() {
            const startOfWeek = this.startDay.clone().startOf("day");
            return Array.from({ length: this.daysCount }).map((_, index) => ({
                name: startOfWeek.clone().add(index, "days").format("dd"),
                date: startOfWeek.clone().add(index, "days").format("DD"),
            }));
        },
        daysCount() {
            const daysCount = {
                day: 1,
                threeDays: 3,
                week: 7,
            };
            return daysCount[this.activeType];
        },
        startOfDay() {
            return this.$moment().startOf("day");
        },
        endOfDay() {
            return this.$moment().endOf("day");
        },
        startOfWeek() {
            return this.$moment().startOf("week");
        },
        endOfWeek() {
            return this.$moment().endOf("week");
        },
        params() {
            const activeTypes = {
                day: {
                    start: () => this.startDay.clone().startOf("day"),
                    end: () => this.startDay.clone().endOf("day"),
                },
                threeDays: {
                    start: () => this.startDay.clone().startOf("day"),
                    end: () => this.startDay.clone().add(2, "days").endOf("day"),
                },
                week: {
                    start: () => this.startDay.clone().startOf("day"),
                    end: () => this.startDay.clone().add(6, "days").endOf("day"),
                },
            };

            return {
                page_size: 10,
                page: 1,
                start: activeTypes[this.activeType]
                    .start()
                    .format("YYYY-MM-DDTHH:mm:ssZ"),
                end: activeTypes[this.activeType].end().format("YYYY-MM-DDTHH:mm:ssZ"),
                organization: this.organization.id,
            };
        },
    },
    created() {
        this.getActivities();
    },
    methods: {
        getActivities() {
            this.loading = true;
            const params = {
                ...this.params,
                start: this.params.start,
                end: this.params.end,
            };
            const url = `/users/activities/`;
            this.$http(url, { params })
                .then(({ data }) => {
                    data.results.forEach((user) => {
                        user.week = this.groupItemsByWeekday(user.events, user.plans);
                    });
                    this.users = data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
       
        groupItemsByWeekday(events, plans) {
            const startOfToday = this.startDay.clone();


            const dayCount = this.activeType === "week" ? 7 : this.activeType === "day" ? 1 : 3;
            const days = Array.from({ length: dayCount }, (_, index) => ({
                events: [],
                plans: [],
                date: this.startDay.clone().add(index, 'days')
            }));

            const filteredEvents = events.filter((event) => event.count);

            [...filteredEvents, ...plans].forEach((item) => {
                const eventDate = this.$moment(item.date, "YYYY-MM-DDTHH:mm:ss").startOf("day");
                const index = this.activeType === "week" ? eventDate.weekday() : eventDate.diff(startOfToday, "days");

                if (index >= 0 && index < dayCount) {
                    days[index][events.includes(item) ? "events" : "plans"].push(item);
                }
            });

            return days;
        },
        async downloadPDF() {
            const params = {
                start: this.params.start,
                end: this.params.end,
                file_type: "xlsx",
                organization: this.organization.id,
            };
            const endpoint = "personal_planes/report/file/";

            try {
                const response = await this.$http(endpoint, {
                    params,
                    responseType: "blob",
                });
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `file.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } catch (error) {
                console.error("Ошибка при загрузке файла:", error);
                this.$message.error("Ошибка при загрузке файла");
            }
        },
        prev() {
            const deltaTimes = {
                week: () => this.startDay.subtract(1, "week"),
                day: () => this.startDay.subtract(1, "days"),
                threeDays: () => this.startDay.subtract(3, "days"),
            };
            this.startDay = deltaTimes[this.activeType]().clone();
            this.getActivities();
        },
        next() {
            const deltaTimes = {
                week: () => this.startDay.add(1, "week"),
                day: () => this.startDay.add(1, "day"),
                threeDays: () => this.startDay.add(3, "days"),
            };
            this.startDay = deltaTimes[this.activeType]().clone();
            this.getActivities();
        },
        today() {
            this.startDay = this.$moment().startOf("day");
            if (this.activeType === 'week') {
                this.startDay = this.startDay.clone().startOf("week")
            }
        },
        handleChangeType(type) {
            this.activeType = type;
            if (this.activeType === 'week') {
                this.startDay = this.startDay.clone().startOf("week")
            }
            this.getActivities();
        },
        async shareFile() {
            const params = {
                start: this.params.start,
                end: this.params.end,
                file_type: "pdf",
                organization: this.organization.id,
            };
            const endpoint = "personal_planes/report/file/";

            try {
                const response = await this.$http(endpoint, {
                    params,
                    responseType: "blob",
                });
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `file.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } catch (error) {
                console.error("Ошибка при загрузке файла:", error);
                this.$message.error("Ошибка при загрузке файла");
            }
        },

        // async shareFile() {

        //     const params = {
        //         start: this.params.start,
        //         end: this.params.end,
        //         file_type: "pdf",
        //         organization: this.organization.id,
        //     };
        //     const endpoint = "personal_planes/report/file/";

        //     try {
        //         const response = await this.$http(endpoint, {
        //             params,
        //             responseType: "blob",
        //         });
        //         const url = window.URL.createObjectURL(response.data);
        //         const shareParams = {
        //             model: 'File',
        //             shareId: this.organization.id,
        //             object: {},
        //             shareUrl: url,
        //             shareTitle: 'file.pdf',
        //         }
        //         this.$store.commit('share/SET_SHARE_PARAMS', shareParams)
        //     } catch(error) {}



        //     // try {
        //     //     this.$store.commit('share/SET_SHARE_PARAMS', shareParams)
        //     // } catch(error) {
        //     //     this.$message.error(this.$t('task.file_share_error'))
        //     //     console.error(error)
        //     // }
        // },
    },
};
</script>

<style scoped lang="scss">
.calendar {
  position: relative;
  overflow-x: auto;
  max-width: 100%;

  margin: 20px;
  font-family: Arial, sans-serif;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
}

th,
td {
  padding: 12px;
  border-bottom: 1px solid #f0f2ff;
  border-right: 1px solid #f0f2ff;
  font-weight: 400;
}

table tr td:first-child,
table tr th:first-child {
  border-left: 1px solid #f0f2ff;
}

table tr th {
  border-top: 1px solid #f0f2ff;
}

table tr:first-child th:first-child {
  border-top-left-radius: 12px;
}

table tr:first-child th:last-child {
  border-top-right-radius: 12px;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}

th:first-child,
td:first-child {
  min-width: 220px;
  width: 300px;
  background-color: #fbfbfb;

  @media (min-width: 640px) {
    position: sticky;
    left: 0;
  }
}

.employee-info {
  display: flex;
  align-items: center;
}

.employee-info .avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.day-column {
  min-width: 130px;
}

</style>