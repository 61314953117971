<template>
    <div class="calendar_page__header">
        <p class="header__item">{{ range }}</p>
        <div class="header__item flex items-center">
            <div class="flex items-center mr-3">
                <a-button type="ui" ghost flaticon icon="fi-rr-angle-small-left" @click="prev" />
                <a-button
                    class="mx-2"
                    @click="today"
                    type="primary" 
                    :disabled="todayButtonDisabled"
                    ghost>
                    Сегодня
                </a-button>
                <a-button type="ui" ghost flaticon icon="fi-rr-angle-small-right" @click="next" />
            </div>
            <a-radio-group 
                class="select-none"
                :value="activeType" 
                @change="handleChangeType($event.target.value)">
                <a-radio-button value="day">
                    День
                </a-radio-button>
                <a-radio-button value="threeDays">
                    3 дня
                </a-radio-button>
                <a-radio-button value="week">
                    Неделя
                </a-radio-button>
            </a-radio-group>
            <transition name="fade">
                <div 
                    v-if="activeType === 'listWeek' || activeType === 'listMonth'" 
                    class="ml-4 flex items-center cursor-pointer"
                    @click="changeSwitchType(mountchActive ? 'listWeek' : 'listMonth')">
                    <a-switch :checked="mountchActive" /> 
                    <span class="ml-2">Месяц</span>
                </div>
            </transition>
        </div>
        
        <div class="header__item">
            <!-- <a-button
                @click="shareFile"
                type="ui" 
                ghost
                flaticon
                icon="fi-rr-share" /> -->
            <a-button
                @click="downloadFile"
                type="primary" 
                ghost>
                Скачать отчет в Excel
            </a-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        activeType: {
            type: String,
            required: true
        },
        downloadFile: {
            type: Function,
            default: () => {}
        },
        shareFile: {
            type: Function,
            default: () => {}
        },
        todayCheck: {
            type: Boolean,
            default: true
        },
        today: {
            type: Function,
            default: () => {}
        },
        prev: {
            type: Function,
            default: () => {}
        },
        next: {
            type: Function,
            default: () => {}
        },
        handleChangeType:{
            type: Function,
            default: () => {}
        },
        addCalendar: {
            type: Function,
            default: () => {}
        },
        related_object: {
            type: [String, Number],
            default: null
        },
        relatedInfo: {
            type: Object,
            default: () => null
        },
        // uKey: {
        //     type: [String, Number],
        //     default: 'default'
        // },
        addEventCheck: {
            type: Boolean,
            default: true
        },
        startDay: {
            type: Object,
            required: true
        }
        // clearEvents: {
        //     type: Function,
        //     default: () => {}
        // }
    },
    computed: {
        todayButtonDisabled() {
            if (this.activeType === 'week') {
                return this.startDay.clone().startOf("week").isSame(this.$moment().startOf("week"), 'day')
            }
            return this.startDay.isSame(this.$moment(), 'day');
        },

        windowWidth() {
            return this.$store.state.windowWidth
        },
        range() {
            const obj = {
                day: this.startDay.format('D MMMM, YYYY'), 
                threeDays: `${this.startDay.format("D")}-${this.startDay.clone().add(2, "days").format("D MMMM - YYYY")}`, 
                week:`${this.startDay.format("D")}-${this.startDay.clone().add(6, "days").format("D MMMM - YYYY")}`, 

            }
            return obj[this.activeType]
        }
    },
    data() {
        return {
            mountchActive: false
        }
    },
    created() {
        if(this.activeType === 'listMonth')
            this.mountchActive = true
    },
    methods: {
        changeSwitchType(value) {
            if(value === 'listMonth')
                this.mountchActive = true
            else
                this.mountchActive = false
            this.handleChangeType({
                target: {
                    value
                }
            })
        },
        getPopupContainer() {
            return this.$refs.calendarHeaderButton
        },
        // handleButtonClick() {
        //     eventBus.$emit('open_event_form', 
        //         null, 
        //         null, 
        //         null, 
        //         this.relatedInfo, 
        //         this.uKey)
        // }
    }
}
</script>

<style lang="scss" scoped>
.calendar_page__header{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px 20px;
    @media (min-width: 970px) {
        flex-direction: row;
        justify-content: space-between;
    }
    h1{
        font-weight: 300;
        font-size: 24px;
        margin: 0px;
    }
}
.header__item {
    margin-bottom: 20px;

    @media (min-width: 970px) {
        margin-bottom: 0;
    }
}
</style>